import { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  where as FWhere,
  limit as FLimit,
  query as FQuery,
  onSnapshot,
} from "firebase/firestore";

const db = getFirestore();

export default function useCollectionSubscribe({
  path,
  where,
  limit,
  orderBy,
}) {
  const [collectionData, setCollectionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let undefinedArguments = false;
    if (where && Array.isArray(where)) {
      where.forEach((item) => {
        if (item[2] === undefined || item[2] === null) {
          undefinedArguments = true;
        }
      });
    }
    if (path && !undefinedArguments) {
      try {
        const args = where.map((opt) => FWhere(...opt));
        if (limit) {
          args.push(FLimit(limit));
        }
        const query = FQuery(collection(db, path), ...args);

        // if (orderBy) {
        //   query = query.orderBy(...orderBy);
        // }

        setLoading(true);
        const schemaUnsubscribe = onSnapshot(
          query,
          (snap) => {
            setCollectionData(
              snap.docs.map((doc) => ({ ...doc.data(), _id: doc.id }))
            );
            setLoading(false);
            setError(false);
          },
          (err) => {
            setError(err);
            setLoading(false);
          }
        );
        return () => schemaUnsubscribe();
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
  }, [path, JSON.stringify(where), limit]);

  return [collectionData, loading, error];
}
