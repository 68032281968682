import PropTypes from "prop-types";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

const SelectOrAddProfile = ({
  profiles,
  handleAdd,
  selected,
  handleEdit,
  defaultSelected,
  color,
  onSelect,
}) => {
  const [selectedDocId, setSelectedDocId] = useState(
    selected || defaultSelected || profiles[0]?._id || ""
  );

  const showEditIcon = handleEdit && (selected || selectedDocId);

  const onClickAdd = () => {
    setSelectedDocId("");
    if (handleAdd) {
      handleAdd();
    }
  };

  return (
    <div className="w-full max-w-lg">
      <div className="flex flex-col md:flex-row w-full flex-wrap mb-2 justify-between items-center md:space-x-2">
        {selected === null && profiles?.length > 0 ? (
          <button
            type="button"
            onClick={() => onSelect(defaultSelected || profiles[0]?._id)}
            className="btn hover:shadow-lg flex items-center border border-gray-300 rounded-full py-1 px-2"
          >
            <XMarkIcon className="text-gray-700 h-5 w-5 mr-2" />
            Cancel
          </button>
        ) : (
          <>
            <div className="flex flex-1 w-full md:w-auto">
              <select
                placeholder="Select an existing profile"
                className={`flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-sm border-greyClear cursor-pointer ${
                  !selected && !selectedDocId && "text-gray-500"
                } ${
                  showEditIcon
                    ? "rounded-l-md border-l border-t border-b border-r-0"
                    : "rounded-md border"
                }`}
                onChange={(e) => {
                  setSelectedDocId(e.target.value);
                  if (onSelect) {
                    onSelect(e.target.value);
                  }
                }}
                label="Select existing"
                value={selected || selectedDocId}
              >
                <option value="" disabled selected hidden>
                  Select an existing profile
                </option>
                {profiles?.map((data) => (
                  <option key={data?._id} className="text-black" value={data?._id}>
                    {data?.["Full Name"]?.[0]}
                  </option>
                ))}
              </select>
              {showEditIcon && (
                <button
                  type="button"
                  className="ml-0 rounded-r-lg border border-greyClear text-gray-700 p-2 hover:bg-gray-50"
                  onClick={() => {
                    handleEdit(selectedDocId);
                  }}
                >
                  <PencilSquareIcon className="h-5 w-5" />
                </button>
              )}
            </div>
            {(selected !== null || profiles?.length === 0) && (
              <div className="flex flex-row items-center mt-2 md:mt-0">
                <span className="mx-4">or</span>
                <button
                  type="button"
                  className="px-4 h-9 btn-primary text-primary"
                  onClick={onClickAdd}
                >
                  <PlusIcon className="h-4 w-4 mr-2" /> Add Person
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

SelectOrAddProfile.propTypes = {
  profiles: PropTypes.array,
  handleAdd: PropTypes.func,
  selected: PropTypes.string,
  handleEdit: PropTypes.func,
};

SelectOrAddProfile.defaultProps = {
  profiles: [],
  handleAdd: null,
  selected: null,
  handleEdit: undefined,
};

export default SelectOrAddProfile;
