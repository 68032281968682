import { useContext } from "react";
import UserContext from "../components/UserContext";
import useCollectionSubscribe from "./useCollectionSubscribe";

export default function useAllProfiles({ orgId, justProfiles = false }) {
  const { user } = useContext(UserContext);

  const orgWhere = orgId ? [["managedBy", "==", orgId]] : [];

  // hack for this to be of length 0 when justProfiles is true
  const removePersonalData = justProfiles ? [["notHere", "==", "1234!!"]] : [];

  const [personalData, personalDataLoading, personalDataError] =
    useCollectionSubscribe({
      path: "PersonalData",
      where: [["User", "==", user?.uid], ...orgWhere, ...removePersonalData],
    });

  const [newProfiles, profilesLoading, error] = useCollectionSubscribe({
    path: `User/${user?.uid}/Profiles`,
    where: [],
  });

  // use the _id from the personal data doc, but the data from the profile doc

  // TODO: change matching to use userProfileId set in the profile doc, instead of name

  const mergeProfiles = (a, b) => {
    const { DEMOGRAPHICS: aDemographics = {}, ...aRest } = a || {};
    const { DEMOGRAPHICS: bDemographics = {}, ...bRest } = b || {};
    return {
      ...aRest,
      ...bRest,
      DEMOGRAPHICS: {
        ...aDemographics,
        ...bDemographics,
      },
    };
  };

  const matches = (a, b) =>
    a?.["Full Name"]?.[0] === b?.["Full Name"]?.[0] ||
    (a?.userProfileId && a.userProfileId === b?._id) ||
    (b?.userProfileId && b.userProfileId === a?._id);

  const generateMergedProfiles = () => {
    const mergedProfiles = [];
    if (personalData?.length > 0) {
      personalData.forEach((legacyProfile) => {
        const newProfile = newProfiles?.find((item) =>
          matches(item, legacyProfile)
        );
        if (newProfile) {
          mergedProfiles.push({
            ...mergeProfiles(legacyProfile, newProfile),
            _id: legacyProfile._id,
            source: "PersonalData",
          });
        } else {
          mergedProfiles.push({ ...legacyProfile, source: "PersonalData" });
        }
      });
    }
    if (newProfiles?.length > 0) {
      // add new profile if it's not in the merged profiles
      newProfiles.forEach((newProfile) => {
        if (!mergedProfiles.find((item) => matches(item, newProfile))) {
          mergedProfiles.push({ ...newProfile, source: "Profiles" });
        }
      });
    }

    return mergedProfiles;
  };

  const profiles = generateMergedProfiles();

  return [profiles, personalDataLoading || profilesLoading];
}
